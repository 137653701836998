import React from "react";
import * as style from "./iconAndLink.module.css";

export function IconAndLink({ icon, alt, text, linkText, href }: any) {
	return (
		<a href={href} className={style.link}>
			<span className={style.iconHolder}>
				<img src={icon} alt={alt} />
			</span>
			<span className={style.textHolder}>
				{text} <br /> {linkText}
			</span>
		</a>
	);
}
