interface IBNAvlinksLocale {
	defLang: string;
	lang: string;
	otherLang: string;
}

export const navlinksLocale = ({
	defLang,
	lang,
	otherLang,
}: IBNAvlinksLocale) => {
	const navPrepared = {
		about: {
			id: 1,
			label: lang === "es" ? "Sobre nosotros" : "About us",
			link: lang === defLang ? "/#about-us" : `/${lang}/#about-us`,
		},

		offers: {
			id: 2,
			label: lang === "es" ? "Servicios" : "Services",
			link: lang === defLang ? "/#services" : `/${lang}/#services`,
		},

		ourWork: {
			id: 3,
			label: lang === "es" ? "Nuestro trabajo" : "Our work",
			link: lang === defLang ? "/our-work/index" : `/${lang}/nuestro-trabajo/index`
		},

		contact: {
			id: 4,
			label: lang === "es" ? "Contactanos" : "Contact us",
			link: lang === defLang ? "#contactanos" : `#contactanos`,
		}

	};

	return navPrepared;
};
