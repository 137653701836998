/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import * as React from "react";
import { useEffect } from "react";
import { IBLayout, IBMetaInfo } from "../../types/types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Seo from "../seo";
import "../../styles/main.css";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Flex } from "../Flex/Flex";
import { IconAndLink } from "../IconAndLink/IconAndLink";

import { useStateContext } from "../../state/ContextProvider";

import * as style from "./layout.module.css";

import footerImage from "../../images/backgroounds/home-process-renovation-footer.png";
import envelopeIcon from "../../images/icons/envelope.svg";
import phoneIcon from "../../images/icons/phone.svg";
import Logo from "../../images/branding/cgr-logo-desktop.svg";

import { navlinksLocale } from "../../util";

const footerData: any = {
	footerInfoText: {
		en: {
			heading: "Get in touch",
			subtext: "We'd love to hear from you!",
		},
		es: {
			heading: "Ponerse en contacto",
			subtext: "¡Nos encantaría saber de usted!",
		},
	},

	telefono: {
		icon: phoneIcon,
		en: {
			name: "Telephone",
			value: "+34677292929",
		},
		es: {
			name: "Teléfono",
			value: "+34677292929",
		},
	},

	email: {
		icon: envelopeIcon,
		en: {
			name: "Email",
			value: "gerard@gransreformes.com",
		},
		es: {
			name: "Email",
			value: "gerard@gransreformes.com",
		},
	},

	bottomFooter: {
		logo: Logo,
	},
};

export default function Layout({
	children,
	lang,
	department,
	otherLang,
	defLang,
	location,
	refs,
	metaInfo,
}: IBLayout) {
	const localNavLinks: any = {
		about: {
			id: 1,

			label: {
				es: "Sobre nosotros",
				en: "About us",
			},
			link: {
				es: "/#about-us",
				en: "/en/#about-us",
			},
		},

		offers: {
			id: 2,
			label: {
				es: "Servicios",
				en: "Services",
			},
			link: {
				es: "/#services",
				en: "/en/#services",
			},
		},

		contact: {
			id: 3,
			label: {
				es: "Contactanos",
				en: "Contact us",
			},
			link: {
				es: "#contactanos",
				en: "#contactanos",
			},
		},

		ourWork: {
			id: 4,
			label: {
				es: "Nuestro trabajo",
				en: "Our work"
			},
			link: {
				es: "/es/nuestro-trabajo/index",
				en: "/our-work/index",
			},
		}
	};

	const navLinks = navlinksLocale({ defLang, lang, otherLang });

	const { footerInfoText, telefono, email } = footerData;

	return (
		<>
			<Seo
				title={metaInfo?.title}
				description={metaInfo?.description}
				keywords={metaInfo?.keywords}
			/>
			<Header
				department={department}
				refs={refs}
				lang={lang}
				otherLang={otherLang}
				defLang={defLang}
				location={location}
				logo={Logo}
			/>
			<div>
				<main>{children}</main>
			</div>

			<Footer>
				<Flex options={{ alignV: true, alignH: true }}>
					<div className="halfFlex">
						<p className={style.footerHeading}>
							{footerInfoText[lang].heading}
						</p>
						<p className={style.footerSubtext}>
							{footerInfoText[lang].subtext}
						</p>
						<IconAndLink
							href={` https://wa.me/34677292929`}
							text={`${telefono[lang].name}`}
							linkText={`${telefono[lang].value}`}
							icon={telefono.icon}
						/>
						<IconAndLink
							href={`mailto: ${email[lang].value}`}
							text={`${email[lang].name}`}
							linkText={`${email[lang].value}`}
							icon={email.icon}
						/>
					</div>
					<div className="halfFlex footerImg">
						<img src={footerImage} alt="" />
					</div>
				</Flex>

				<Flex
					options={{ alignV: true, alignH: true }}
					className={style.bottomFooter}
				>
					<div className={style.flexThird} id="contactanos">
						<img src={Logo} alt="" />
					</div>

					<div className={style.flexThird}>
						<ul>
							<li>
								<Link to={navLinks.about.link}>
									{navLinks.about.label}
								</Link>
							</li>
							<li>
								<Link to={navLinks.offers.link}>
									{navLinks.offers.label}
								</Link>
							</li>
						</ul>
					</div>

					<div className={style.flexThird}>
						{/* <ul>
							<li></li>
							<li></li>
						</ul> */}
					</div>
				</Flex>

				<Flex options={{ alignV: true, alignH: true }}>
					<p className={style.copy}>© 2022. All rights reserved.</p>
				</Flex>
			</Footer>
		</>
	);
}
