import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
// import { useMediaQuery } from "react-responsive";
// import { StaticImage } from "gatsby-plugin-image";
// import { slide as Menu } from "react-burger-menu";
// import { Nav } from "./Nav";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { slide as Menu } from "react-burger-menu";
import { useIntersection } from "../../hook/useIntersection";
import classNames from "classnames";

import * as style from "./header.module.css";

import { Link } from "gatsby";
import { Container } from "../Container/Container";
import { Flex } from "../Flex/Flex";

import { navlinksLocale } from "../../util";

export default function Header({
	className,
	siteTitle,
	lang,
	otherLang,
	defLang,
	refs,
	location,
	department,
	logo,
}: any) {
	const [isOpen, setIsOpen] = useState(false);
	const [scrollDirection, setScrollDirection] = useState("up");

	const navLinks = navlinksLocale({
		defLang,
		lang,
		otherLang: otherLang[0].iso,
	});

	let position = 0;

	if (typeof window !== "undefined") {
		position = window.pageYOffset;
	}

	const handleScroll = () => {
		if (typeof window !== "undefined") {
			let _cur = window.pageYOffset;

			if (position > _cur && _cur > 0 && scrollDirection === "down") {
				setScrollDirection("up");
			}

			if (position < _cur && scrollDirection === "up") {
				setScrollDirection("down");
			}

			position = _cur;
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("scroll", handleScroll);
			}
		};
	}, [scrollDirection]);

	// Create nav items for displaying
	const finalNavItems = Object.values(navLinks).map(navItem => {
		return (
			<li key={navItem.id}>
				<Link
					onClick={() => setIsOpen(false)}
					className={style.link}
					to={`${navItem.link}`}
				>
					{navItem.label}
				</Link>
			</li>
		);
	});

	const mobileMenuClass = classNames(style.mobileNav, {
		[style.active]: isOpen,
	});

	return (
		<>
			<header
				className={`${className} ${style.header} ${
					isOpen ? style.lock : ""
				} ${scrollDirection === "down" ? style.hide : style.show}`}
			>
				<Container>
					<Flex
						className={style.containerHeader}
						options={{ alignV: true, alignH: true }}
					>
						<div className={style.logo}>
							<Link to={`${defLang === lang ? "/" : "/" + lang}`}>
								<img src={logo} alt="" />
							</Link>
						</div>
						{/* END Logo */}

						<nav className={style.desktopNav}>
							<ul className={style.desktoNavList}>
								{finalNavItems}
							</ul>
						</nav>

						<div>
							<button
								className={style.nav}
								onClick={() => setIsOpen(!isOpen)}
							>
								<span className={style.bar}></span>
								<span className={style.bar}></span>
								<span className={style.bar}></span>
							</button>

							<ul className={style.langList}>
								<li>
									<Link
										to={`${
											defLang !== lang
												? "/"
												: "/" + otherLang[0].iso
										}`}
										className={style.langSwicherLink}
									>
										{defLang === lang
											? otherLang[0].iso.toUpperCase()
											: defLang.toUpperCase()}
									</Link>
								</li>
							</ul>
						</div>
					</Flex>
				</Container>
			</header>

			<div className={mobileMenuClass}>
				<nav>
					<ul>
						{finalNavItems}
						<li>
							<Link
								to={`${
									defLang !== lang
										? "/"
										: "/" + otherLang[0].iso
								}`}
								className={style.langSwicherLink}
							>
								{defLang === lang
									? defLang.toUpperCase()
									: lang.toUpperCase()}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</>
	);
}
