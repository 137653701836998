import React, { forwardRef } from "react";
import { Container } from "../Container/Container";

import * as style from "./footer.module.css";

export const Footer = forwardRef<HTMLDivElement, any>(({ children }, ref) => {
	return (
		<footer ref={ref} className={style.footer}>
			<Container>{children}</Container>
		</footer>
	);
});
