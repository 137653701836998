import React from "react";
import * as style from "./container.module.css";
import classNames from "classnames";

interface IBContainer {
	children: JSX.Element | JSX.Element[] | string | string[];
	className?: string | string[];
	id?: string;
}

export function Container({ children, className, ...rest }: IBContainer) {
	const classes = classNames(style.container, className);

	return (
		<div {...rest} className={classes}>
			{children}
		</div>
	);
}
