import React from "react";
import classNames from "classnames";
import { IBFlex } from "../../types/types";
import * as style from "./flex.module.css";

export function Flex({ children, options, className, ...rest }: IBFlex) {
	const classes = classNames(style.flex, className, {
		[style.vertical]: options.alignV,
		[style.horizontal]: options.alignH,
		[style.gap]: options.gap,
		[style.reverse]:options.reverse
	});

	return <div className={classes} {...rest}>{children}</div>;
}
