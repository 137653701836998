import React from "react";
import classNames from "classnames";
import { IBSection } from "../../types/types";

import * as style from "./section.module.css";

export function Section({ children, className, ...rest }: IBSection) {
	const classes = classNames(style.section, className);

	return (
		<section className={classes} {...rest}>
			{children}
		</section>
	);
}
